<template>
  <div class="main">
    <el-form :model="form"
             ref="formRef">
      <el-row :gutter="10">
        <el-col :lg="8"
                :md="12"
                :sm="12"
                :xl="8"
                :xs="24">
          <el-button @click="toGroupTest"
                     v-show="this.$route.query.grade_id == 1 ">组卷测试</el-button>
          <el-button @click="toAllTest">整卷测试</el-button>
          <el-button @click="toTeacherPaper">教师试卷</el-button>
          <el-button @click="allExport">批量导出</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-table border
              highlight-current-row
              type=""
              :stripe="true"
              :max-height="$store.state.tableHeight + 50 "
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="user_paper_id"
                       align="center"
                       label="试卷ID" />
      <el-table-column prop="template_name"
                       align="center"
                       label="试卷" />
      <el-table-column prop="judge_status"
                       align="center"
                       label="试卷状态" />
      <el-table-column prop="subject_name"
                       align="center"
                       sortable
                       label="科目" />

      <el-table-column prop="paper_time"
                       align="center"
                       sortable
                       label="创建时间" />
      <el-table-column prop="name"
                       align="center"
                       fixed="right"
                       label="操作">
        <template slot="header">
          操作
        </template>

        <template slot-scope="{row}">

          <div class="text_btn_primary"
               @click="toScanCard(row)">上传答题卡</div>

          <div class="text_btn_primary"
               @click="checkInfo(row)">查看</div>

          <div class="text_btn_primary"
               @click="exportBtn(row)">导出</div>
          <div class="text_btn_danger"
               @click="deleteInfo(row)">删除</div>

        </template>

      </el-table-column>
    </el-table> -->

    <Table ref="TablesRef"
           :showSelection="true"
           @setArr="handleSelectionChanges"
           :tableData="tableData" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

  </div>
</template>

<script>
import { saveAs } from "file-saver"
import { printPdf } from '@/api/dataAnalysis.js'
export default {
  name: 'checkTest',
  data () {
    return {
      form: {
        user_id: '',
        detection_id: '',
        ShowTestUser: '',
        page: 1,
        limit: 10
      },
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      rowDetectionId: '',
      allExportList: [],
      pageName: 'view_test_paper',
      tableColumns: [
        { prop: 'user_paper_id', align: 'left', label: '试卷ID', width: '' },
        { prop: 'template_name', align: 'left', label: '试卷', width: '' },
        { prop: 'subject_name', align: 'left', label: '科目', width: '' },
        { prop: 'paper_time', align: 'left', label: '创建时间', width: '' },
        { prop: 'judge_status', align: 'left', label: '状态', width: '', isStatus: true },

        {
          prop: 'd', align: 'left', label: '操作', width: '280', type: 'text', btns: [
            { name: '上传答题卡', class: 'text_btn_primary',event: 'toScanCard' },
            { name: '查看',class: 'text_btn_primary', event: 'checkInfo' },
            { name: '导出',class: 'text_btn_primary', event: 'exportBtn' },
            { name: '删除',class: 'text_btn_danger', event: 'deleteInfo' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.getSubject()
    // window.localStorage.getItem('user_paper_id')
    console.log('829', this.$route.query)
    this.$route.query.grade_id = window.localStorage.getItem('data_grade_id') ? window.localStorage.getItem('data_grade_id') : this.$route.query.grade_id
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.marginBottom = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '110px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'

    window.onpopstate = function (event) {
      location.reload(); // 当路由回退时重新加载整个页面
    };

    this.setTablesColums()
  },
  methods: {
    handleSelectionChanges (arr) {
      // console.log('arr',arr)
      this.allExportList = arr

    },
    allExport () {
      if (this.allExportList.length == 0) {
        this.$message.info('请先选择导出的试卷')
      } else {
        this.allExportList.map(item => {
          this.exportBtn(item)
        })
      }



    },
    getClass (prop, value, row) {
      if (prop == 'judge_status') {
        console.log()
        // return value == 0 ? 'pass' : 'normal_status'
        if (value == 0) {
          return 'unload'
        } else if (value == 101) {
          return 'wpg'
        } else {
          return 'ypg'
        }
      }


    },
    getValue (prop, value, row) {

      if (prop == 'judge_status') {
        if (value == 0) {
          return '未上传答题卡'
        } else if (value == 101) {
          return '未批改'
        } else {
          return '已批改'
        }
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    toScanCard (row) {
      console.log('fdasgadsbaf', row)
      if (row.judge_status == 4) {
        this.$notify({
          title: '提示',
          message: '不能上传答题卡!',
          type: 'error'
        });
      } else {
        this.$router.push(
          {
            path: '/dataAnalysis/viewTestPaper/scanCard',
            query: {
              url: row.url,
              paper_no_nstandard_sheet_id: row.paper_no_nstandard_sheet_id,
              user_paper_id: row.user_paper_id

            }
          })
      }

    },
    async exportBtn (row) {
      const data = await printPdf({ user_paper_id: row.user_paper_id })
      let blob = new Blob([data], { type: 'application/json;charset=utf-8' });

      saveAs(blob, `${row.user_paper_id}试卷.pdf`)
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      let id = window.localStorage.getItem('detection_id')
      this.form.detection_id = id
      this.$http({
        url: '/api/v1/tang/detection_paper_list',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    deleteInfo (row) {
      console.log('row', row)
      console.log('b', this.rowDetectionId)
      this.$confirm("确定将其删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        this.$http({

          url: '/api/v1/tang/del_paper',
          method: 'post',
          data: {
            detection_id: this.$route.query.detection_id || window.localStorage.getItem('detection_id'),
            user_paper_id: row.user_paper_id,
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })

      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
      })
    },
    postInfo () {
      console.log('this', this.detection_id)
      this.$http({

        url: '/api/v1/tang/del_paper',
        method: 'post',
        data: {
          detection_id: this.$route.query.detection_id || window.localStorage.getItem('detection_id'),
          user_paper_id: row.user_paper_id,
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '删除成功!',
          type: 'success'
        });
        this.refresh()
      })

    },
    toGroupTest () {
      let id = window.localStorage.getItem('detection_id')
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/groupTest',
          query: {
            detection_id: id,
            user_paper_id: window.localStorage.getItem('user_paper_id')
          }
        })
    },
    toAllTest () {
      let id = window.localStorage.getItem('detection_id')
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/allTest',
          query: {
            detection_id: id,
            user_paper_id: window.localStorage.getItem('user_paper_id')
          }
        })
    },
    toTeacherPaper () {
      let id = window.localStorage.getItem('detection_id')
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/teacherPaper',
          query: {
            detection_id: id,
            user_paper_id: window.localStorage.getItem('user_paper_id')
          }
        })
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    async checkInfo (row) {
      // let id = window.localStorage.getItem('detection_id')
      window.localStorage.setItem('template_name', row.template_name)
      window.localStorage.setItem('subject_name', row.subject_name)
      this.$router.push(
        {
          path: '/dataAnalysis/viewTestPaper/view',
          query: {
            user_paper_id: row.user_paper_id,
            subject_name: row.subject_name,
            template_name: row.template_name
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 12px 12px 0 12px;
  background: white;
  font-family: Regular;
}
::v-deep .el-row {
  margin-bottom: 16px;
}
::v-deep .el-button {
  padding: 0;
  width: 88px;
  height: 32px;
}
.pedding {
  color: green;
}
::v-deep .opertion_image_warp .el-button--mini {
  border: none;
  width: 86px;
  height: 32px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  background: none;
  font-size: 14px;
}
::v-deep .opertion_image_warp:nth-of-type(4) {
  /* 在这里编写你要应用的样式 */
  span {
    color: red;
  }
}

::v-deep .el-button--primary.is-plain:hover {
  color: #3f9eff !important;
}
::v-deep .wpg {
  width: auto;
}
::v-deep .ypg {
  width: auto;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
::v-deep .el-table__row {
  height: 100px;
}
::v-deep .el-table__row {
  height: 67px;
}
</style>